import type { Children } from '../../types';
import type { CursorProps } from './Cursor';

import * as React from 'react';
import { Cursor } from './Cursor';

interface CursorContextInterface {
  enabled: boolean;
  cursor: CursorProps;
  setCursor: React.Dispatch<React.SetStateAction<{ active: number; }>>;
}

interface CursorContextProviderProps extends Children {
  enable: boolean;
}

const CursorContext = React.createContext(null as any);
export const useCursorContext = (): CursorContextInterface => {
  const context = React.useContext(CursorContext);
  if (context === undefined) {
    throw new Error('useCursorContext must be used within CursorContextProvider.');
  }

  return context as CursorContextInterface;
}

export const CursorContextProvider = (props: CursorContextProviderProps) => {
  const { children, enable } = props;
  const [cursor, setCursor] = React.useState<CursorProps>({ active: 0 });
  const [enabled, setEnabled] = React.useState<boolean>(false);

  const messageHandler = (event: MessageEvent) => {
    if (event.data?.name === 'orbit:change:editing' && event.data?.data) {
      setEnabled(JSON.parse(event.data.data));
    }
  };

  React.useEffect(() => {
    setEnabled(enable);
  }, [enable]);

  React.useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const cursorContextValues: CursorContextInterface = {
    enabled,
    cursor,
    setCursor,
  };

  return (
    <CursorContext.Provider value={cursorContextValues}>
      <Cursor/>
      {children}
    </CursorContext.Provider>
  )
};
