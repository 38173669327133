import { customOptions } from '../contexts/AppContext/custom-options';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (typeof window.gtag !== ['function']) return;
  window.gtag('config', `${customOptions.googleAnalyticsId}`, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (typeof window.gtag !== 'function') return;
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
