import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: 'G-NDJ1RPC4PM',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '774c706e-cda1-4bc9-b42b-22112424f585',
  footerLogoId: 'd989bdfc-e91d-4c59-8566-5e61fee4c837',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Didigames',
  titleTemplate: 'or-site-didigames | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '16073612-b4a9-4ffb-942d-2d342cc884ed',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      strategy: 'afterInteractive',
      charset: 'UTF-8',
      'data-domain-script': '16073612-b4a9-4ffb-942d-2d342cc884ed'
    },
    {
      id: 'ot-callback-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      // Todo: Nunjucks the GA tracking IDs.
      src: `https://www.googletagmanager.com/gtag/js?id=G-NDJ1RPC4PM`,
      strategy: 'afterInteractive'
    },
    {
      id: 'gtag-datalayer-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-NDJ1RPC4PM', {
              page_path: window.location.pathname,
            });
          `
      }
    },
  ],
  links: [
  ],
};